.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.profile-info {
    margin-bottom: 20px;
}

.profile-info p {
    margin: 0 0 10px;
}

.profile-info label {
    display: block;
    margin-bottom: 10px;
}

.reset-password h3 {
    margin-bottom: 10px;
}

.reset-password form {
    display: flex;
    flex-direction: column;
}

.reset-password label {
    margin-bottom: 10px;
}

.reset-password input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.reset-password button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
}

.reset-password button:hover {
    background-color: #45a049;
}

.message {
    margin-top: 10px;
    color: red;
}