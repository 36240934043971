.input-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.input-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
}

.status-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

textarea {
    resize: none;
    padding: 5px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 3px;
}

.add-button {
    margin-top: 10px;
    font-size: 12px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
}