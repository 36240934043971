table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
}

th {
    background-color: #f2f2f2;
}

.Orders {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.htContainer {
    width: 100%;
}

.Orders h2 {
    font-size: 24px;
}

.htCore {}

/* Ensure header cells have some right padding to accommodate the button */
.ht_master th,
.ht_clone_left th {
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
}

/* Adjust the dropdown button (filter button) */
.htDropdownMenu .htUIButton {
    height: 20px !important;
    line-height: 20px !important;
    padding: 0 !important;
    right: 4px !important;
    top: 50% !important;
    transform: translateY(-50%);
}

/* Remove the fixed width for the items column */
.refresh-button {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
}