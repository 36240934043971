.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.banner {
    background-color: #282c34;
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
}

.user-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.user-icon {
    position: relative;
    display: inline-block;
}

.user-icon img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    min-width: 150px;
}

.dropdown a,
.dropdown button {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    cursor: pointer;
    white-space: nowrap;
    text-align: left;
}

.dropdown button {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
}

.dropdown a:hover,
.dropdown button:hover {
    background-color: #f0f0f0;
}

.main-content {
    display: flex;
    flex: 1;
}

.navbar {
    background-color: #f8f9fa;
    width: 200px;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.navbar ul {
    list-style-type: none;
    padding: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    max-height: 500px;
    /* Adjust based on content */
}

.navbar ul.collapsed {
    max-height: 0;
}

.navbar li {
    margin-bottom: 10px;
}

.navbar a {
    text-decoration: none;
    color: #007bff;
    display: block;
    padding: 10px;
    border-radius: 5px;
}

.navbar a:hover {
    background-color: #e9ecef;
}

.toggle-nav {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
}

.content {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}